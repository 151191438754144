import {InitialValuesTypes} from "../interfaces/InitialValuesTypes";
import {Dispatch, FormEvent, SetStateAction} from "react";
import {Inventory} from "../models/Inventory";
import {SplitTypes} from "../enums/Skybox/SplitTypes";

export const validate = (values: InitialValuesTypes, setIsDisabled: Dispatch<SetStateAction<boolean>>) => {
    let haveAnError = false;

    for (const [key, value] of Object.entries(values)) {
        if (!value) {
            haveAnError = true;
        }

        if (key === 'seats') {
            const select = document.getElementsByName(key)[0] as HTMLSelectElement;

            if (select.value === '') {
                haveAnError = true;
            }
        }
    }

    setIsDisabled(haveAnError);
};

export const quantityOptions = (inventory: Inventory) => {
    let tempOptions: { name: string, value: string }[] = [{name: '', value: ''}];
    const quantity = (inventory.shown_quantity < inventory.quantity && inventory.shown_quantity)
        ? inventory.shown_quantity
        : inventory.quantity;

    for (let i = 1; i <= quantity; i++) {
        if (inventory.split_type === SplitTypes.Custom) {
            if (inventory.custom_split && inventory.custom_split[i]) {
                tempOptions.push({name: String(i), value: String(i)});
            }
        } else {
            tempOptions.push({name: String(i), value: String(i)});
        }
    }

    if ((inventory.split_type === SplitTypes.NeverLeaveOne && quantity > 1))
        tempOptions.splice(tempOptions.length - 2, 1);

    return tempOptions;
};

export const onQuantityChange = (inventory: Inventory, event?: FormEvent<HTMLSelectElement>, value: number = 0) => {
    if (event) {
        value = Number((event.target as HTMLSelectElement).value);
    }

    const select = document.getElementsByName('seats')[0] as HTMLSelectElement;

    while (select.options.length > 0) {
        select.remove(0);
    }

    select.add(new Option('', ''));

    if (value === 1) {
        for (let i = inventory.low_seat; i <= inventory.high_seat; i++) {
            select.add(new Option(`${i}-${i}`, `${i}-${i}`));
        }
    } else if (value !== 0) {
        const groupCount = inventory.high_seat - inventory.low_seat - value + 1;

        for (let i = 0; i <= groupCount; i++) {
            const seats = `${inventory.low_seat + i}-${inventory.low_seat + i + value - 1}`;

            select.add(new Option(seats, seats));
        }
    }
};
