import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {getCurrencies} from "../@api/Currency";
import {Currency} from "../models/Currency";
import {setCurrenciesState} from "../store/reducers/currencies";

export default function useCurrenciesGet() {
    const milliseconds = 1000 * 60 * 60;
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrencies();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const setCurrencies = () => {
        getCurrencies()
            .then((response) => {
                const tempCurrencies: Currency[] = [];

                response.data.forEach((currency: Currency) => {
                    if (currency.is_main) tempCurrencies.push(currency);
                });

                dispatch(setCurrenciesState({currencies: tempCurrencies}));
            })
    };

    return setCurrencies;
};
