import {Inventory} from "../../../models/Inventory";
import React, {FC, FormEvent, useState} from "react";
import {Form, Formik} from "formik";
import {TextInput} from "../../../models/Inputs/TextInput";
import InputSwitch from "../../../ui/Inputs/InputSwitch/InputSwitch";
import "./HoldForm.scss"
import {postInventoriesHold} from "../../../@api/Inventory";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {InitialValuesTypes} from "../../../interfaces/InitialValuesTypes";
import {onQuantityChange, quantityOptions, validate} from "../../../helpers/InventoryModal";

interface Props {
    inventory: Inventory,
    showHandle: () => void,
}

const HoldForm: FC<Props> = ({inventory, showHandle}) => {
    const initialValues = {quantity: '', seats: '', expiry_date: '', display_name: '', email: '', phone: ''};
    const holdInputsSecond = [
        {type: 'text', name: 'display_name', placeholder: 'Name'},
        {type: 'email', name: 'email', placeholder: 'Email'},
        {type: 'phone', name: 'phone', placeholder: 'Phone Number'},
    ];
    const user = useSelector((state: RootState) => state.userSlice.user);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const holdInputsFirst = [
        {
            type: 'select',
            name: 'quantity',
            placeholder: 'Quantity',
            options: quantityOptions(inventory),
            onSelect: (function (e: FormEvent<HTMLSelectElement>) {
                onQuantityChange(inventory, e);
            })
        },
        {
            type: 'select', name: 'seats', placeholder: 'Seats',
            options: [],
        },
        {type: 'date', name: 'expiry_date', placeholder: 'Expiry Date', showTimeSelect: true},
    ];

    const handleHoldSubmit = (values: InitialValuesTypes) => {
        if (user) {
            const userTimezoneOffset = new Date(values.expiry_date ?? '').getTimezoneOffset() * 60000;

            values.expiry_date = new Date(new Date(values.expiry_date ?? '').getTime() - userTimezoneOffset);

            postInventoriesHold(user.id, inventory.id, values)
                .then(() => {
                    showHandle();
                })
                .catch((e: Error) => {
                    alert(e);
                });
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: InitialValuesTypes) => handleHoldSubmit(values)}
            validate={(values: InitialValuesTypes) => validate(values, setIsDisabled)}
            enableReinitialize>
            <Form className="hold hold__form">
                <div className="hold__form_title">Hold until:</div>
                <div className="hold__form_wrapper">
                    <div className="hold__form_inputs">
                        {holdInputsFirst.map((value: TextInput) => (
                            InputSwitch(value)
                        ))}
                    </div>
                    <div className="hold__form_inputs">
                        {holdInputsSecond.map((value: TextInput) => (
                            InputSwitch(value)
                        ))}
                    </div>
                </div>
                <button className="button button_submit modal-submit" type="submit" disabled={isDisabled}>
                    Submit
                </button>
            </Form>
        </Formik>
    )
}

export default HoldForm;
